<template>
  <div class="after-sales-details containercopy">
    <!-- 编号信息 -->
    <!-- <div class="serial-num-info">
      <el-card class="box-card">
        <div class="header">
          <div class="header-l">
            <el-tag>{{afterOrderInfo.service_status_str}}</el-tag>
            <span>售后编号:{{afterOrderInfo.service_sn}}</span>
          </div>
          <div class="header-r">
            <span>申请人:{{afterOrderInfo.phone}}</span>
            <span>申请时间:{{afterOrderInfo.create_time}}</span>
          </div>
        </div>
        <div class="price">
          <span>退款金额:</span>
          <span>¥{{afterOrderInfo.refund_price}}</span>
        </div>
        <el-divider></el-divider>
        <div class="desc">
          <div v-html="afterOrderInfo.tips"></div>
        </div>
      </el-card>
    </div> -->

    <!-- 新编号信息 -->
    <div>
      <el-card>
        <div class="head_info">
          <span class="head_left"><span>{{ afterOrderInfo.service_status_str }}</span><span style="font-size: 14px;font-weight: 400;color: #ff514b;margin-left: 10px;">{{afterOrderInfo.service_countdown}}</span></span>
          <span class="head_right"
            >售后编号:{{ afterOrderInfo.service_sn }}</span
          >
        </div>
        <div class="info_price">
          <span class="info_price_left">退款金额:</span>
          <span class="info_price_right"
            >¥{{ afterOrderInfo.total_refund_price }}</span
          >
          <span
            :class="
              afterOrderInfo.service_sponsor == 1 ? 'redText' : 'blueText'
            "
            >{{
              afterOrderInfo.service_sponsor == 1 ? "用户发起" : "商户发起"
            }}</span
          >
        </div>
        <div class="info_person">
          <span style="margin-right:24px;"
            >申请人:{{ afterOrderInfo.phone }}</span
          >
          <span>申请时间:{{ afterOrderInfo.create_time }}</span>
        </div>
        <div class="head_deliver"></div>
        <div class="info_tips">
          <div v-html="afterOrderInfo.tips"></div>
        </div>
      </el-card>
    </div>
    <!-- 商品信息 -->
    <div class="good-info">
      <el-card>
        <div class="good-info-l">
          <div class="lineH-box">
            <div class="lineH"></div>
            <div>售后商品</div>
          </div>
          <!-- <div class="title">售后商品</div> -->
          <div v-for="(item, index) in afterOrderInfo.goods_list" :key="index">
            <template v-if="index < 3">
              <span>{{ item.goods_name }}</span>
              <span class="sku_name">{{ item.sku_name }}</span>
            </template>
          </div>
          <div
            class="more"
            v-if="
              afterOrderInfo.goods_list && afterOrderInfo.goods_list.length > 3
            "
          >
            <el-link type="primary" @click="moreGoodsClick">更多</el-link>
          </div>
        </div>
        <div class="good-info-c">
          <div class="lineH-box">
            <div class="lineH"></div>
            <div>售后信息</div>
          </div>
          <!-- <div class="title">售后信息</div> -->
          <div class="info-c">
            <span class="label">售后方式:</span
            ><span
              :class="
                afterOrderInfo.service_type == '1'
                  ? 'service-box1'
                  : 'service-box2'
              "
              >{{ afterOrderInfo.service_type | serviceTypeText }}</span
            >
          </div>
          <div class="info-c">
            <span class="label">退款金额:</span
            ><span class="highlight">{{ afterOrderInfo.refund_price }}</span>
            <span
              v-if="
                afterOrderInfo.service_status == 0 &&
                  (module_id_array.includes('158') ||
                    module_id_array.includes('999'))
              "
              class="textBtn"
              @click="show_refound_price = true"
              >修改退款金额
            </span>
          </div>
          <div class="info-c">
            <span class="label">退运费金额:</span
            ><span class="highlight">{{ refund_freight_price }}</span>
            <span
              v-if="
                afterOrderInfo.is_freight_price &&
                  afterOrderInfo.service_status != 1 &&
                  afterOrderInfo.service_status != 5 &&
                  (module_id_array.includes('196') ||
                    module_id_array.includes('999'))
              "
              class="textBtn"
              @click="show_refund_freight_price = true"
              >修改退运费金额
            </span>
          </div>
          <div class="info-c">
            <span class="label">退款件数:</span
            ><span>{{ afterOrderInfo.service_num }}</span>
          </div>
          <div class="info-c">
            <span class="label">联系方式:</span
            ><span>{{ afterOrderInfo.phone }}</span>
          </div>
          <div class="info-c">
            <span class="label">退款原因:</span
            ><span>{{ afterOrderInfo.refund_desc }}</span>
          </div>
          <div class="info-c">
            <span class="label">退款说明:</span>
            <div>{{ afterOrderInfo.refund_remark }}</div>
          </div>
          <div style="display: flex;padding-left: 68px;">
            <div
              v-for="(img, index) in afterOrderInfo.refund_image"
              :key="index"
            >
              <el-image
                style="width: 100px; height: 100px"
                :src="img"
                :preview-src-list="afterOrderInfo.refund_image"
              >
              </el-image>
            </div>
          </div>
        </div>
        <div class="good-info-r">
          <div class="lineH-box">
            <div class="lineH"></div>
            <div>购买信息</div>
          </div>
          <!-- <div class="title">购买信息</div> -->
          <div class="info-c">
            <span class="label">商品费用:</span
            ><span>¥{{ afterOrderInfo.goods_price }}</span>
          </div>
          <div class="info-c">
            <span class="label">实付金额:</span
            ><span>¥{{ afterOrderInfo.pay_money }}</span>
          </div>
          <div class="info-c">
            <span class="label">订单编号:</span
            ><span>{{ afterOrderInfo.order_sn }}</span>
          </div>
          <!-- <div class="info-c">
            <span class="label">购买件数:</span
            ><span>{{ afterOrderInfo.order_num }}</span>
          </div> -->
        </div>
        <div
          class="service-address"
          v-if="
            afterOrderInfo.status != 2 &&
              afterOrderInfo.service_type != 1 &&
              address_info_true
          "
        >
          <div class="lineH-box">
            <div class="lineH"></div>
            <div>退货地址</div>
          </div>
          <!-- <div class="title">退货地址</div> -->
          <div>
            <span class="label">联系电话:</span>
            <span>{{ address_info.phone }}</span>
          </div>
          <div>
            <span class="label">联系人:</span>
            <span>{{ address_info.user_name }}</span>
          </div>
          <div>
            <span class="label">退货地址:</span>
            <span>{{
              `${address_info.province_name}${address_info.city_name}${address_info.district_name}${address_info.address}`
            }}</span>
          </div>
        </div>
      </el-card>
    </div>

    <!-- 物流信息 -->
    <div class="logistics-info" v-if="afterOrderInfo.express_sn">
      <el-card>
        <div class="title">退货物流</div>
        <div class="content">
          <div class="content-l">
            <div>物流公司:{{ afterOrderInfo.express_name }}</div>
            <div>物流单号:{{ afterOrderInfo.express_no }}</div>
            <div>
              备注:{{
                !afterOrderInfo.express_remark
                  ? "无"
                  : afterOrderInfo.express_remark
              }}
            </div>
            <el-button
              type="text"
              style="font-size:14px"
              @click="show_logistics_list = true"
              >查看物流
            </el-button>
          </div>
          <el-dialog
            title="查看物流"
            :visible.sync="show_logistics_list"
            width="800px"
            :close-on-click-modal="true"
          >
            <div class="content-r">
              <el-timeline>
                <el-timeline-item
                  v-for="(item, index) in afterOrderInfo.logistics_list"
                  :key="index"
                  :timestamp="item.time"
                  placement="top"
                  size="large"
                >
                  <el-card>
                    <h4>{{ item.context }}</h4>
                  </el-card>
                </el-timeline-item>
              </el-timeline>
            </div>
          </el-dialog>
        </div>
      </el-card>
    </div>

    <!-- 协商记录 -->
    <div class="consultation-record">
      <el-card>
        <div class="title" style="margin-bottom:10px;">协商记录</div>
        <div class="log">
          <div
            v-if="afterOrderInfo.log && !afterOrderInfo.log.length"
            class="desc"
          >
            {{ afterOrderInfo.title }}
          </div>
          <el-timeline v-else>
            <el-timeline-item
              v-for="(item, index) in reverseAfterOrderInfoLog"
              :key="index"
              :timestamp="item.create_time"
              :icon="item.icon"
              placement="top"
              size="large"
            >
              <el-card>
                <h4>{{ item.service_title }}</h4>
                <p v-html="item.service_desc"></p>
              </el-card>
            </el-timeline-item>
          </el-timeline>
        </div>
      </el-card>
    </div>

    <!-- 操作 -->
    <div
      class="handle-btn"
      v-if="
        afterOrderInfo.service_status == 0 || afterOrderInfo.service_status == 4
      "
    >
      <template
        v-if="
          module_id_array.includes('140') || module_id_array.includes('999')
        "
      >
        <el-button
          v-if="
            afterOrderInfo.service_status == 0 &&
              afterOrderInfo.service_type == 1
          "
          type="primary"
          @click="agreeBtn()"
          >同意并退款</el-button
        >
      </template>
      <template>
        <el-button
          v-if="
            afterOrderInfo.service_status == 0 &&
              afterOrderInfo.service_type == 2 &&
              (module_id_array.includes('140') ||
                module_id_array.includes('999'))
          "
          type="primary"
          @click="agreeBtn('back')"
          >同意申请</el-button
        >
        <el-button
          v-if="
            afterOrderInfo.service_status == 4 &&
              afterOrderInfo.service_type == 2 &&
              (module_id_array.includes('142') ||
                module_id_array.includes('999'))
          "
          type="primary"
          @click="agreeBtn()"
          >确认收货并退款</el-button
        >
      </template>
      <el-button
        v-if="
          module_id_array.includes('141') || module_id_array.includes('999')
        "
        @click="refuseBtn"
        >拒绝</el-button
      >
    </div>

    <!-- 更多商品 -->
    <el-dialog title="售后商品" :visible.sync="dialogGoods">
      <el-table :data="afterOrderInfo.goods_list">
        <el-table-column
          property="goods_name"
          label="商品名称"
          width="150"
        ></el-table-column>
        <el-table-column
          property="sku_name"
          label="规格"
          width="200"
        ></el-table-column>
        <el-table-column property="num" label="数量"></el-table-column>
      </el-table>
    </el-dialog>

    <!-- 拒绝理由 -->
    <el-dialog title="填写拒绝理由" :visible.sync="dialogReason" width="470px">
      <el-form :model="reasonForm" :rules="reasonRules" ref="reasonFormRef">
        <el-form-item label="" prop="refuse_desc">
          <el-input
            type="textarea"
            v-model="reasonForm.refuse_desc"
            :autosize="{ minRows: 3, maxRows: 10 }"
            placeholder="理由"
            style="width:370px;"
            maxlength="50"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitReason('reasonFormRef')"
            >确定</el-button
          >
          <el-button @click="resetReason('reasonFormRef')">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <!-- 确认提示 -->
    <el-dialog
      title="提示"
      :visible.sync="dialogSuccess"
      :show-close="false"
      width="20%"
    >
      <span>退款完成，请查看或修改商品库存</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="goBack">知道了</el-button>
      </span>
    </el-dialog>
    <!-- 确认提示 -->
    <el-dialog
      title="提示"
      :visible.sync="show_refound_price"
      :show-close="false"
      width="30%"
    >
      <div class="refound_box">
        <div>退款金额：</div>
        <el-input-number
          size="medium"
          :min="0"
          style="width:200px;"
          placeholder="请输入"
          v-model="refund_price"
          :controls="false"
        ></el-input-number>
        <!-- <el-input v-model="refund_price"
                          style="width:200px;"
                          placeholder="请输入"></el-input> -->
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="subRefound">确定</el-button>
        <el-button @click="cancelRefound">取消</el-button>
      </span>
    </el-dialog>
    <!-- 退运费金额确认提示 -->
    <el-dialog
      title="提示"
      :visible.sync="show_refund_freight_price"
      :show-close="false"
      width="30%"
    >
      <div class="refound_box">
        <div>退运费金额：</div>
        <el-input-number
          size="medium"
          :min="0"
          style="width:200px;"
          placeholder="请输入"
          v-model="afterOrderInfo.refund_freight_price"
          :controls="false"
        ></el-input-number>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="subRefoundfreight">确定</el-button>
        <el-button @click="cancelRefoundfreight">取消</el-button>
      </span>
    </el-dialog>
    <!-- 验证码弹框 -->
    <el-dialog
      title="验证码验证"
      :visible.sync="verificationShow"
      width="510px"
      style="text-align: left"
      :close-on-click-modal="false"
      @close="closeThis"
    >
      <div>
        <div>
          为保障数据安全，订单退款需填写歧黄助手接收到的验证码，验证成功后方可查看。
        </div>
        <div style="display:flex;align-items: center;margin-top:50px;">
          <div>验证码：</div>
          <div>
            <el-input v-model="code" placeholder="验证码"></el-input>
          </div>
          <div>
            <el-button
              @click="sendCode"
              type="primary"
              :disabled="canClick"
              style="width: 120px;margin-left: 40px;"
              size="small"
              >{{ btnText }}</el-button
            >
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="verificationShow = false">取 消</el-button>
        <el-button type="primary" :disabled="!code" @click="certenBtn()"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 退货退款的确认信息弹窗 -->
    <el-dialog
      title="退款确认"
      :visible.sync="certenAfterAdress"
      width="510px"
      style="text-align: left"
      :close-on-click-modal="false"
    >
      <el-form :model="address_info" label-width="100px" ref="address_info">
        <el-form-item label="联系人" prop="service_name">
          <el-input
            v-model="address_info.user_name"
            style="width: 370px"
          ></el-input>
        </el-form-item>
        <el-form-item label="联系电话" prop="service_phone">
          <el-input
            v-model="address_info.phone"
            style="width: 370px"
          ></el-input>
        </el-form-item>

        <el-form-item label="省市区" prop="regions">
          <el-cascader
            v-model="address_info.regions"
            :options="regions"
            :props="goodsTypeSet"
            placeholder="选择地区"
            style="width: 370px"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="详细地址" prop="service_address">
          <el-input
            type="textarea"
            :autosize="{ minRows: 3, maxRows: 10 }"
            placeholder="详细地址"
            v-model="address_info.address"
            style="width: 370px"
            maxlength="50"
            show-word-limit
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="certenAfterAdress = false">取 消</el-button>
        <el-button type="primary" @click="afterSales()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import commonUtil from "../../utils/commonUtil.js";
import afterSales from "../../api/afterSales";
export default {
  data() {
    return {
      module_id_array: window.utils.storage.getter("module_id_array") || [],
      afterOrderInfo: {
        service_address: {},
      },
      dialogGoods: false,
      dialogReason: false,
      dialogSuccess: false,
      show_logistics_list: false,
      reasonForm: {
        refuse_desc: "",
      },
      reasonRules: {
        refuse_desc: [
          { required: true, message: "请填写拒绝理由", trigger: "blur" },
        ],
      },
      reverseAfterOrderInfoLog: [],
      show_refound_price: false, //修改退款金额弹窗
      show_refund_freight_price: false, //修改退运费金额弹窗
      refund_price: "", //退款的金额
      refund_freight_price: "", //退运费金额
      verificationShow: false,
      btnText: "发送验证码",
      canClick: false,
      totalTime: 60,
      code: "",
      statusInfo: "",
      certenAfterAdress: false,
      regions: [], //地区
      address_info: {
        regions: [],
        city_name: "",
        district_name: "",
        province_name: "",
        service_address: "",
        service_city_id: "",
        service_district_id: "",
        service_name: "",
        service_phone: "",
        service_province_id: "",
      },
      address_info_true: "",
      goodsTypeSet: {
        value: "value",
        label: "label",
        children: "children",
      },
    };
  },
  filters: {
    serviceTypeText(val) {
      switch (val) {
        case 1:
          return "仅退款";
        case 2:
          return "退货退款";
      }
    },
    // handleText(val) {
    //   switch (val) {
    //     case 2:
    //       return '同意并退款';
    //     case 3:
    //       return '同意并发送退货地址';
    //     case 4:
    //       return '同意并发送退货地址';
    //   }
    // }
  },
  computed: {
    getServiceId() {
      return this.$route.query.service_id;
    },
  },
  created() {
    this.getafterorderinfo();
    this.getRegion();
  },
  methods: {
    getafterorderinfo() {
      let params = {
        service_id: this.getServiceId,
      };
      afterSales.getafterorderinfo(params).then((res) => {
        let { data } = res;
        data.tips = data.tips.replace(/\\n/g, "<br>");
        data.log = data.log.map((item, index) => {
          item.icon =
            item.service_type == 1
              ? "iconfont icon-mai"
              : "iconfont icon-shangjia1";
          item.service_desc = item.service_desc.replace(/\\n/g, "<br>");
          return item;
        });
        data.service_address.service_address = !data.service_address
          .service_address
          ? ""
          : data.service_address.service_address;
        data.service_address.service_name = !data.service_address.service_name
          ? ""
          : data.service_address.service_name;
        data.service_address.service_phone = !data.service_address.service_phone
          ? ""
          : data.service_address.service_phone;
        // data.logistics_list
        this.afterOrderInfo = data;
        this.refund_price = this.afterOrderInfo.refund_price || "0";
        this.reverseAfterOrderInfoLog = data.log.reverse();
        this.address_info = data.service_address;
        this.address_info_true = data.address_info;
        this.address_info.regions = [
          data.service_address.province_id,
          data.service_address.city_id,
          data.service_address.district_id,
        ];
        this.refund_freight_price = data.refund_freight_price;
        console.log(data);
        console.log(this.address_info);
      });
    },
    // 更多商品
    moreGoodsClick() {
      this.dialogGoods = true;
    },
    agreeBtn(val) {
      commonUtil.lostFocus(event);
      this.statusInfo = val ? val : "";
      console.log("0123", val, this.statusInfo);
      // 同意申请不走验证码流程 新增填写物流信息
      if (this.statusInfo) {
        // let params = {
        //   service_id: this.afterOrderInfo.service_id,
        //   code: this.code,
        // };
        // let interfaceName =
        //   this.afterOrderInfo.service_status == 0
        //     ? "agreeafterorder"
        //     : "confirmAndRefund";
        // afterSales[interfaceName](params).then((res) => {
        //   if (this.statusInfo === "back") {
        //     this.$router.go(-1);
        //   } else {
        //     this.dialogSuccess = true;
        //   }
        //   let { code } = res;
        //   console.log(!code);
        //   if (!code) {
        //     this.$message.success("处理成功");
        //   }
        // });
        this.certenAfterAdress = true;
        return;
      }
      this.verificationShow = true;
    },
    afterSales() {
      const address_info_now = {
        province_id: this.address_info.regions[0],
        city_id: this.address_info.regions[1],
        district_id: this.address_info.regions[2],
        address: this.address_info.address,
        phone: this.address_info.phone,
        user_name: this.address_info.user_name,
      };
      let params = {
        service_id: this.afterOrderInfo.service_id,
        code: this.code,
        address_info: address_info_now,
      };
      let interfaceName =
        this.afterOrderInfo.service_status == 0
          ? "agreeafterorder"
          : "confirmAndRefund";
      console.log(params);
      afterSales[interfaceName](params).then((res) => {
        if (this.statusInfo === "back") {
          this.$router.go(-1);
        } else {
          this.dialogSuccess = true;
        }
        let { code } = res;
        console.log(!code);
        if (!code) {
          this.$message.success("处理成功");
        }
      });
    },
    transRegions(tree) {
      tree.forEach((item) => {
        if (item.city || item.district) {
          item.children = item.city || item.district;
          delete item.city;
          delete item.district;
          this.transRegions(item.children);
        }
        if (item.province_id) {
          item.value = item.province_id;
          delete item.province_id;
        }
        if (item.city_id) {
          item.value = item.city_id;
          delete item.city_id;
        }
        if (item.district_id) {
          item.value = item.district_id;
          delete item.district_id;
        }

        if (item.name) {
          item.label = item.name;
          delete item.name;
        }
      });
    },
    //获取省市区列表
    getRegion() {
      let url = "/store/platform/getProvinceCityDistrict";
      this.axios.post(url).then((res) => {
        let regions = res.data.list;
        this.transRegions(regions);
        this.regions = regions;
      });
    },
    goBack() {
      commonUtil.lostFocus(event);
      this.dialogSuccess = false;
      this.$router.go(-1);
    },
    refuseBtn() {
      commonUtil.lostFocus(event);
      this.dialogReason = true;
    },
    submitReason(formName) {
      commonUtil.lostFocus(event);
      let check = false;
      this.$refs[formName].validate((valid) => {
        check = valid || false;
      });
      if (!check) {
        return;
      }
      let params = {
        service_id: this.afterOrderInfo.service_id,
        refuse_desc: this.reasonForm.refuse_desc,
      };
      afterSales.refuseafterorder(params).then((res) => {
        let { code } = res;
        if (!code) {
          this.$message.success("处理成功");
          this.dialogReason = false;
          this.$router.go(-1);
        }
      });
    },
    resetReason(formName) {
      commonUtil.lostFocus(event);
      this.$refs[formName].resetFields();
      this.dialogReason = false;
    },
    // 退款确认
    subRefound() {
      console.log("123", this.refund_price);
      const data = {
        service_id: this.afterOrderInfo.service_id,
        refund_price: this.refund_price,
      };
      console.log("data", data);
      afterSales.changeRefund(data).then((res) => {
        if (res.code !== -1) {
          this.show_refound_price = false;
          this.getafterorderinfo();
          this.$notify.success({
            title: res.data,
          });
        }
      });
    },
    cancelRefound() {
      this.show_refound_price = false;
      this.refund_price = this.afterOrderInfo.refund_price;
    },
    // 退运费金额确认
    subRefoundfreight() {
      const data = {
        service_id: this.afterOrderInfo.service_id,
        refund_freight_price: this.afterOrderInfo.refund_freight_price,
      };
      console.log("data", data);
      afterSales.editAfterOrderRefundFreightPrice(data).then((res) => {
        if (res.code !== -1) {
          this.show_refund_freight_price = false;
          this.getafterorderinfo();
          this.$notify.success({
            title: res.data,
          });
        }
      });
    },
    cancelRefoundfreight() {
      this.show_refund_freight_price = false;
      this.afterOrderInfo.refund_freight_price = this.refund_freight_price;
    },
    /** 获取验证码 */
    sendCode() {
      if (this.canClick) return;
      const data = {
        service_id: this.afterOrderInfo.service_id,
      };
      console.log("0123", data);
      this.axios
        .post("/store/shopafterorder/getAfterOrderCode", data)
        .then((res) => {
          this.canClick = true;
          this.btnText = this.totalTime + "s后重新发送";
          let clock = window.setInterval(() => {
            this.totalTime--;
            this.btnText = this.totalTime + "s后重新发送";
            if (this.totalTime < 0) {
              window.clearInterval(clock);
              this.btnText = "重新发送验证码";
              this.totalTime = 60;
              this.canClick = false;
            }
          }, 1000);
        });
    },
    closeThis() {
      this.code = "";
    },
    certenBtn() {
      const title =
        "请确认退款给客户¥" +
        (Number(this.afterOrderInfo.refund_price) +
          Number(this.afterOrderInfo.refund_freight_price)).toFixed(2) +
        "元。";
      this.$confirm(title, "请确认", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          let params = {
            service_id: this.afterOrderInfo.service_id,
            code: this.code,
          };
          let interfaceName =
            this.afterOrderInfo.service_status == 0
              ? "agreeafterorder"
              : "confirmAndRefund";
          afterSales[interfaceName](params).then((res) => {
            if (this.statusInfo === "back") {
              this.$router.go(-1);
            } else {
              this.dialogSuccess = true;
            }
            let { code } = res;
            console.log(!code);
            if (!code) {
              this.$message.success("处理成功");
            }
          });
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.el-card.is-always-shadow {
  box-shadow: none;
}
.containercopy {
  padding: 20px;
  box-sizing: border-box;
  height: calc(100vh - 145px);
  background-color: rgb(240, 243, 247);
  border-radius: 5px;
  box-sizing: border-box;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
}
.el-card__body {
  border-radius: 12px;
}
.after-sales-details {
  font-size: 16px;
  padding: 0;
  .serial-num-info {
    .box-card {
      .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .header-l {
          display: flex;
          align-items: center;
          .el-tag {
            height: 30px;
            line-height: 30px;
            font-size: 18px;
            font-weight: bold;
            margin-right: 20px;
          }
          span {
            font-size: 16px;
            font-weight: bold;
          }
        }
        .header-r {
          font-size: 16px;
          font-weight: bold;
          span:first-child {
            margin-right: 20px;
          }
        }
      }
      .price {
        font-size: 16px;
        font-weight: bold;
        margin-top: 20px;
        margin-bottom: 20px;
        padding-left: 10px;
      }
      .desc {
        font-size: 16px;
        padding-left: 10px;
        color: #adadad;
      }
    }
  }
  .good-info {
    width: 100%;
    margin-top: 20px;
    .el-card {
      width: 100%;
      ::v-deep .el-card__body {
        // height: 300px;
        padding-right: 40px;
        display: flex;
        justify-content: space-between;
        box-sizing: border-box;
        & > div {
          width: 25%;
          height: 100%;
          .label {
            margin: 0;
            white-space: nowrap;
            width: 80px;
          }
        }
      }
    }
    .title {
      margin-bottom: 10px;
      font-weight: bold;
    }
    .service-box1 {
      color: #ff514b;
      font-weight: normal;
    }
    .service-box2 {
      color: rgba(0, 162, 255, 1);
      font-weight: normal;
    }
    .highlight {
      color: #ff514b;
      font-weight: bold;
    }
    .textBtn {
      font-size: 14px;
      color: #4974f5;
      cursor: pointer;
    }
    .good-info-l {
      height: 100%;
      .sku_name {
        margin-left: 20px;
        color: #adadad;
      }
      .more {
        margin-top: 10px;
      }
    }
    .good-info-c {
      height: 100%;
    }
    .info-c {
      display: flex;
      align-items: center;
      padding: 2px 0;
      span {
        margin-left: 6px;
      }
    }
    .good-info-r {
      height: 100%;
    }
    .service-address {
      height: 100%;
      div {
        display: flex;
        align-items: center;
        padding: 2px 0;
        span {
          margin-left: 6px;
        }
      }
    }
  }
  .logistics-info {
    margin-top: 20px;
    .title {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 20px;
    }
    .content {
      .content-r {
      }
    }
  }
  .consultation-record {
    margin-top: 20px;
    .title {
      font-size: 18px;
      font-weight: bold;
    }
    .desc {
      font-weight: bold;
    }
    .log {
      ::v-deep .el-timeline {
        .el-timeline-item__node--large {
          left: -20px;
          width: 50px;
          height: 50px;
          background-color: #fff;
        }
        .iconfont {
          font-size: 32px;
          // color: red;
          color: #1989fa;
        }
      }
    }
  }
  .handle-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
.head_info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 23px;
  margin-bottom: 19px;
  .head_left {
    font-size: 16px;
    font-weight: bold;
    color: #000000;
  }
  .head_right {
    font-size: 15px;
    color: #999999;
  }
}

.info_price {
  height: 33px;
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  .info_price_left {
    font-size: 15px;
    font-weight: bold;
    color: #333333;
    margin-right: 2px;
  }
  .info_price_right {
    font-size: 23px;
    font-weight: 500;
    color: #ff7e28;
  }
  .redText {
    margin-left: 5px;
    height: 16px;
    padding: 0 2px;
    line-height: 16px;
    background-color: rgb(236, 128, 141);
    font-weight: normal;
    font-size: 12px;
    color: #ffffff;
  }
  .blueText {
    margin-left: 5px;
    height: 16px;
    padding: 0 2px;
    line-height: 16px;
    background-color: rgb(58, 142, 230);
    font-weight: normal;
    font-size: 12px;
    color: #ffffff;
  }
}
.info_person {
  height: 22px;
  font-size: 15px;
  color: #333333;
  line-height: 22px;
}
.head_deliver {
  width: 100%;
  height: 2px;
  background: #f4f4f4;
  margin: 3px 0 14px 0;
}
.info_tips {
  font-size: 13px;
  color: #666666;
}
.lineH-box {
  display: flex;
  align-items: center;
  margin-bottom: 7px;
  font-weight: bold;
  font-size: 15px;
  color: #333333;
  .lineH {
    width: 3px;
    height: 18px;
    background: #4974f5;
    border-radius: 21px;
    margin-right: 8px;
  }
}
.refound_box {
  display: flex;
  align-items: center;
}
</style>
